export default {
    setBrands: (state, data) => {
        state.brands = data
    },
    setVendors:(state, data) => {
        state.vendors = data
    },
    setInvoices: (state, data) => {
        state.invoices = data
    },
}