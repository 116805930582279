import axios from "axios";
const url = 'https://kodebright-billing-webapi.azurewebsites.net/api/';
//test
export default {
    getBrands({ commit }) {
        axios.get(url + "brand").then(data => {
            commit('setBrands', data.data);
        });
    },
    getVendors({ commit }) {
        axios.get(url + "vendor").then(data => {
            commit('setVendors', data.data);
        });
    },
    getInvoices({ commit }) {
        axios.get(url + "invoice").then(data => {
            commit('setInvoices', data.data);
        });
    },
    postInvoice({ commit }, payload) {
        axios.post(url + "invoice", payload).then(data => {
            console.log(payload,"Posted", data);
        }).catch(error => {
            console.log("Error", error);
        });
    },
}