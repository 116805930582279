<template>
  <h5>New Invoice</h5>
  <!-- <input v-model="invoiceDate" type="datetime"></input> -->

  <b-container
    class="mt-5"
  >
    <b-container align="left">
      <h3 class="">
        Invoice Summary
      </h3>
      <b-row>
        <b-col>
          <label for="invoiceDate">Invoice Date</label>
          <DxDateBox
            id="invoiceDate"
            v-model="invoiceDate"
          />
        </b-col>
        <b-col>
          <label for="dueDate">Due Date</label>
          <DxDateBox
            id="dueDate"
            v-model="dueDate"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="brandId">Brand</label>
          <b-form-select
            id="brandId"
            v-model="brandId"
            :options="brandOptions"
          />
        </b-col>
        <b-col>
          <label for="vendorId">Vendor</label>
          <b-form-select
            v-model="vendorId"
            :options="vendorOptions"
          />
        </b-col>
      </b-row>
    </b-container>
          

    <b-container
      align="left"
      class="mt-5"
    >
      <h3>Invoice Details</h3>
      <b-button
        variant="primary"
        class="mt-2"
        @click="addRow"
      >
        Add Row
      </b-button>
      <DxDataGrid 
        id="grid-container"
        ref="invoiceDetails"
        class="mt-2"
        :show-borders="true"
        :data-source="invoiceDetails"
        key-expr="invoiceDetailId"
      >
        <DxEditing mode="popup">
          <DxTexts confirm-delete-message="" />
        </DxEditing>
   
        <DxColumn
          data-field="service_Date"
          data-type="date"
        />
        <DxColumn data-field="service_Description" />
        <DxColumn data-field="rate" />
        <DxColumn data-field="quantity" />
      </DxDataGrid>
    </b-container>
    <b-button
      variant="primary"
      class="mt-2 pull-right"
      @click="CreateInvoice"
    >
      Create
    </b-button>
  </b-container>
</template>

<script>
import { mapState }  from 'vuex'; 
import { DxDataGrid, DxColumn, DxEditing, DxTexts } from "devextreme-vue/data-grid";
import { BButton } from 'bootstrap-vue-3';
import { DxDateBox } from 'devextreme-vue/date-box';

import config from 'devextreme/core/config';
import repaintFloatingActionButton from 'devextreme/ui/speed_dial_action/repaint_floating_action_button';

export default {
  
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxTexts,
    BButton,
    DxDateBox
  },
  data() {
    var today = new Date();
    return {
      
      invoiceDate: today,
      dueDate: new Date(today.getFullYear(), today.getMonth() + 1, 0),
      brandId: null,
      vendorId: null,
      invoiceDetails:[ ],
    };
  },
  computed: {
    ...mapState({
      brands: state => state.billing.brands,
      vendors: state => state.billing.vendors,
    }),
    brandOptions: function () {
      return this.brands.map(b => ( {value: b.id, text: b.name } ))
    },
    vendorOptions: function () {
      return this.vendors.map(v => ( { value: v.id, text: v.name } ));
    },
    grid() {
      return this.$refs["invoiceDetails"].instance;
    },
  },
  mounted() {
    this.$store.dispatch('billing/getBrands');
    this.$store.dispatch('billing/getVendors');
    config({
        floatingActionButtonConfig: "up",
      });

      repaintFloatingActionButton();
  },
  methods: {
    addRow() {
      this.grid.addRow();
      this.grid.deselectAll();
    },

    deleteRow() {
      this.grid.deleteRow(this.selectedRowIndex);
      this.grid.deselectAll();
    },

    editRow() {
      this.grid.editRow(this.selectedRowIndex);
      this.grid.deselectAll();
    },
    CreateInvoice() {
      let invoiceDetails = [];
      this.invoiceDetails.forEach(id => {
        invoiceDetails.push({
          service_Date: id.service_Date,
          service_Description: id.service_Description,
          quantity: id.quantity,
          rate: id.rate
        });
      });

      let payload = {
        brandId: this.brandId,
        vendorId: this.vendorId,
        invoice_Date: this.invoiceDate,
        due_Date: this.dueDate,
        invoiceDetails: invoiceDetails
      };

      this.$store.dispatch('billing/postInvoice', payload).then((data) => {
        this.$router.push({ name: "Invoice" });
      });
    },
  }
}
</script>
