import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {BootstrapVue3, BToastPlugin} from 'bootstrap-vue-3';
//import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import 'devextreme/dist/css/dx.light.css';

const app = createApp(App);

app.use(router);
app.use(BootstrapVue3);
app.use(BToastPlugin);
app.use(store);

app.mount('#app');