import state from './state.js';
import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

const billing = {
    namespaced:true,
    state,
    actions,
    mutations,
    getters
};

export default billing;