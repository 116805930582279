import { createWebHistory, createRouter } from "vue-router";
import Home from "../components/Home.vue";
import Invoice from '../components/invoice/Index.vue';
import InvoiceCreate from '../components/invoice/Create.vue';


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/Invoice",
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/Invoice/Create",
    name: "Invoice Create",
    component: InvoiceCreate,
  },
];
const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  routes,
});


export default router;
