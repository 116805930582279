<template>
  <!-- <div class="container">
    Invoice Index
    {{ invoices }}
  </div> -->
  <DxDataGrid
    id="grid-container"
    :show-borders="true"
    :data-source="invoices"
    key-expr="id"
  >
    <DxColumn data-field="id" caption="Invoice Number" />
    <DxColumn data-field="brand" />
    <DxColumn data-field="vendor" />
    <DxColumn data-field="invoice_Date"  data-type="date"  />
    <DxColumn data-field="due_Date"  data-type="date" />
    <DxColumn data-field="subTotal_Amount" data-type="number"  :format="currencyFormat" />
    <DxColumn data-field="tax_Amount" date-type="number"  :format="currencyFormat" />
    <DxColumn data-field="total_Amount" data-type="number"  :format="currencyFormat" />
    <DxMasterDetail :enabled="true" template="masterDetailTemplate" />

    <template #masterDetailTemplate="{ data: invoice }">
      <!-- <div>{{ invoice.data.invoiceDetails }}</div> -->
      <DxDataGrid
        id="grid-container"
        :show-borders="true"
        :data-source="invoice.data.invoiceDetails"
        key-expr="invoiceDetailId"
      >
        <DxColumn data-field="service_Date" data-type="date"/>
        <DxColumn data-field="service_Description" />
        <DxColumn data-field="rate" />
        <DxColumn data-field="quantity" />
      </DxDataGrid> 
    </template>
  </DxDataGrid>

  <DxButton text="New Invoice" @click="newInvoice" />
</template>
<script>
import { mapState }  from 'vuex'; 
import DxButton from "devextreme-vue/button";
import { DxDataGrid, DxColumn, DxMasterDetail } from "devextreme-vue/data-grid";

export default {
  components: {
    DxButton,
    DxDataGrid,
    DxColumn,
    DxMasterDetail
  },
  data() {
    return {
      invoice: null,
      currencyFormat: "CA$ ,##0.###"
      //{ style: 'currency', currency: 'CAD', useGrouping: true, minimumSignificantDigits: 3 }
    };
  },
  computed: {
    ...mapState({
      invoices: state => state.billing.invoices,
    }),
  },
  mounted() {
    this.$store.dispatch('billing/getInvoices');
  },
  methods: {
    newInvoice() {
      this.$router.push({ name: "Invoice Create" });
    },
  },
};
</script>
