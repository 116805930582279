
import billing from './modules/billing'

import Vuex from "vuex";

const store = new Vuex.Store({
    modules: {
        billing: billing
      }
});
  
export default store;